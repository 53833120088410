import { ref, onMounted, onUnmounted } from 'vue';

export function useScrollProgress() {
    const scrollProgress = ref(0);

    const updateScrollProgress = () => {
        const scrollTop = window.scrollY;
        const docHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercentage = (scrollTop / docHeight) * 100;
        scrollProgress.value = Math.min(100, scrollPercentage);
    };

    onMounted(() => {
        window.addEventListener('scroll', updateScrollProgress);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', updateScrollProgress);
    });

    return {
        scrollProgress,
    };
}
