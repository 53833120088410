<template>
    <div :class="CSSClasses" class="aside-block">
        <Eyebrow v-if="heading" :hasUnderline="true" :hasMargin="false" class="eyebrow">
            {{ heading }}
        </Eyebrow>
        <slot></slot>
    </div>
</template>

<script setup>
const props = defineProps({
    heading: {
        type: String,
        default: '',
    },
    hasPadding: {
        type: Boolean,
        default: true,
    },
});

const CSSClasses = computed(() => {
    return {
        '--padding': props.hasPadding,
    };
});
</script>

<style lang="scss" scoped>
.--padding {
    @include content-padding;
}

.eyebrow {
    margin-bottom: 2.2rem;
}
</style>
