<template>
    <div class="main-with-asides-tier" :class="CSSClasses">
        <section class="body">
            <aside class="main-left">
                <div class="main-left-wrapper">
                    <slot name="main-left" />
                </div>
            </aside>

            <div class="main-center">
                <slot name="main-center"> </slot>
            </div>

            <aside class="main-right">
                <slot name="main-right" />
            </aside>
        </section>
    </div>
</template>
<script setup>
const props = defineProps({
    hideAsidesMobile: {
        type: Boolean,
        required: false,
    },
});
const CSSClasses = computed(() => [props.hideAsidesMobile ? 'hide-asides-mobile' : null]);
</script>

<style lang="scss" scoped>
.body {
    @include content-section;
    @include grid;

    @include media-query(mobile) {
        padding-inline-start: 0;
        padding-inline-end: 0;
    }
}

.main-left {
    grid-column: 1 / span 3;

    @include media-query(mobile) {
        order: 3;
        padding-inline-start: var(--col-gutter);
    }

    @include media-query(tablet) {
        grid-column: span 6;
    }

    @include media-query(phone) {
        padding-inline-end: var(--col-gutter);
    }
}

.main-left-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
}

.main-center {
    @include content-padding;
    grid-column: 4 / span 6;

    @include media-query(mobile) {
        grid-column: 1 / -1;
    }
}

.main-right {
    grid-column: 10 / span 3;

    @include media-query(mobile) {
        padding-inline-end: var(--col-gutter);
        order: 2;
        margin-bottom: 3em;
    }

    @include media-query(tablet) {
        grid-column: span 6;
    }

    @include media-query(phone) {
        padding-inline-start: var(--col-gutter);
    }
}

.main-left,
.main-center,
.main-right {
    @include media-query(phone) {
        grid-column: 1 / -1;
    }
}

.hide-asides-mobile {
    .main-left,
    .main-right {
        display: none;
        @include media-query(tablet-mw) {
            display: block;
        }
    }
}
</style>
